<template>
    <div class="pages">
        <router-view />
        <div class="ui-tabbar">
            <van-tabbar v-model="activeName" active-color="#8fc42e" @change="changeTab">
                <van-tabbar-item name="home2" icon="wap-home-o">首页</van-tabbar-item>
                <van-tabbar-item name="yc-station" icon="shop-o">社区团长</van-tabbar-item>
<!--                <van-tabbar-item name="groupon" icon="bag-o">团购</van-tabbar-item>-->
                <van-tabbar-item name="my" icon="user-o">我的</van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Index',
    data() {
        return {
            activeName: 'home'
        }
    },
    mounted() {
        this.activeName = this.$route.name
    },
    methods: {
        changeTab() {
            this.$router.replace({
                name: this.activeName
            })
        }
    }
}
</script>
