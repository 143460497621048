import Vue from 'vue'
import axios from 'axios'
import Vant from 'vant'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './icons'
import 'vant/lib/index.css'
import './assets/scss/index.scss'
import storage from "./utils/storage"
import 'swiper/swiper.min.css';


Vue.prototype.$showLoading = function (){
    store.state.showLoading = true;
}
Vue.prototype.$hideLoading = function (){
    store.state.showLoading = false;
}

Vue.config.productionTip = false
Vue.use(Vant)

function logout() {
    store.commit('logout')
    router.replace({
        path: '/login',
        query: {redirect: router.history.current.fullPath}
    })
}

/**
 * 检查是否需要token，true为要验证
 */
function isCheck(config) {
    const urls = [
        '/',
        '/api/wechatOfficial/v1/auth/login'
    ]
    return urls.indexOf(config.url) < 0
}

/**
 * 请求加入token
 * @param config
 * @returns {Promise<unknown>}
 */
async function setToken(config) {
    if (isCheck(config)) {
        const currentTime = new Date().getTime()
        const expireTime = storage.getNumber('expireTime')
        const refreshToken = storage.getItem('refreshToken')
        if (expireTime < currentTime && refreshToken) {
            config.headers['token'] = await getToken()
        } else {
            config.headers['token'] = store.state.token
        }
    }
    return config
}

async function getToken() {
    const refreshToken = storage.getItem('refreshToken')
    const response = await fetch(window.SITE_CONFIG.baseUrl + '/api/wechatOfficial/v1/auth/refreshToken', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': refreshToken,
            'appId': window.SITE_CONFIG.appId,
        }
    })
    if (response.status !== 200) {
        return ''
    }
    const responseJson = await response.json()
    if (!responseJson || responseJson.code !== 0) {
        return ''
    }
    store.commit('refreshToken', {
        token: responseJson.data.token,
        refreshToken: responseJson.data.refreshToken,
        expireTime: responseJson.data.expire * 1000 + new Date().getTime()
    })
    return responseJson.data.token
}

let instance = axios.create({
    baseURL: window.SITE_CONFIG.baseUrl,
    timeout: 1000 * 30,
    // withCredentials: false,
    // headers: {
    //     'Content-Type': 'application/json; charset=utf-8'
    // }
})

instance.interceptors.request.use(
    function (config) {
        config.headers['appId'] = window.SITE_CONFIG.appId
        // config.headers['token'] = getToken(config)
        return setToken(config)
    },
    function (error) {
        return Promise.reject(error);
    });

instance.interceptors.response.use(
    function (response) {
        if (response.status === 401) {
            logout()
        }
        if (response.status !== 200) {
            throw new Error(response.status + ', ' + response.statusText)
        }
        if (response.data.code === 401) {
            logout()
        }
        if (response.data.code === 500) {
            return Promise.reject(response.data);
            //throw new Error(response.data.msg)
        }
        return response.data;
    },
    function (error) {
        return Promise.reject(error);
    }
);
instance.postForm = function (url,data){
   let formData = new FormData();
    for (let dataKey in data) {
        formData.append(dataKey,data[dataKey]);
    }
   return instance.post(url,formData)
}
Vue.prototype.$axios = instance

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
