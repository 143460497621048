import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Ycty from "@/views/ycty/Ycty";
import Hahl from "@/views/hahl/Hahl";
import store from '../store/index'
import {Toast} from 'vant';
import axios from 'axios'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index,
        children: [
            // {
            // path: '',
            // name: 'home',
            // component: () => import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
            // },
            {
                path: '',
                name: 'home2',
                component: () => import(/* webpackChunkName: "home" */ '../views/home/Home2.vue'),
            },
            // },
            {
                path: 'station',
                name: 'yc-station',
                component: () => import(/* webpackChunkName: "home" */ '../views/home/Station.vue'),
            },
            {
            path: 'shop',
            name: 'shop',
            component: () => import(/* webpackChunkName: "shop" */ '../views/home/Shop.vue'),
        }, {
            path: 'groupon',
            name: 'groupon',
            component: () => import(/* webpackChunkName: "groupon" */ '../views/home/Groupon.vue'),
        }, {
            path: 'my',
            name: 'my',
            component: () => import(/* webpackChunkName: "my" */ '../views/home/My.vue'),
        }
        ]
    },
    //渔菜桃源
    {
        path: '/ycty',
        name: 'ycty',
        component: Ycty,
        children: [{
            path: 'lifeHome',
            name: 'lifeHome',
            meta:{
                title:'生活馆'
            },
            component: () => import(/* webpackChunkName: "home" */ '../views/ycty/LifeHome.vue'),
        }, {
            path: 'tySpace',
            name: 'tySpace',
            meta:{
                title:'桃源空间'
            },
            component: () => import(/* webpackChunkName: "my" */ '../views/ycty/TySpace.vue'),
        }
        , {
            path: 'tySpace1',
            name: 'tySpace1',
            meta:{
                title:'桃源空间'
            },
            component: () => import(/* webpackChunkName: "my" */ '../views/ycty/TySpace1.vue'),
        }, {
            path: 'tyHotel',
            name: 'tyHotel',
            meta:{
                title:'桃源别墅'
            },
            component: () => import(/* webpackChunkName: "my" */ '../views/ycty/TyHotel.vue'),
        }
        , {
            path: 'videoConduct',
            name: 'videoConduct',
            meta:{
                title:'桃源影视'
            },
            component: () => import(/* webpackChunkName: "my" */ '../views/ycty/VideoConduct.vue'),
        }, {
            path: 'history',
            name: 'history',
            meta:{
                title:'桃源故事'
            },
            component: () => import(/* webpackChunkName: "groupon" */ '../views/ycty/History.vue'),
        }, {
            path: 'historyDetails',
            name: 'historyDetails',
            meta:{
                title:'桃源故事'
            },
            component: () => import(/* webpackChunkName: "groupon" */ '../views/ycty/HistoryDetails.vue'),
        }, {
            path: 'joinUs',
            name: 'joinUs',
            meta:{
                title:'桃源结义'
            },
            component: () => import(/* webpackChunkName: "shop" */ '../views/ycty/JoinUs.vue'),
        }, {
            path: 'tySpaceDetails',
            name: 'tySpaceDetails',
            meta:{
                title:'桃源空间'
            },
            component: () => import(/* webpackChunkName: "shop" */ '../views/ycty/TySpaceDetails'),
        },
          {
            path: 'tySpaceSubmit',
            name: 'tySpaceSubmit',
          meta:{
              title:'提交订单'
          },
            component: () => import(/* webpackChunkName: "shop" */ '../views/ycty/TySpaceSubmit'),
         }
         ,
        {
            path: 'tySpaceOrderList',
            name: 'tySpaceOrderList',
            meta:{
                title:'渔菜订单'
            },
            component: () => import(/* webpackChunkName: "shop" */ '../views/ycty/TySpaceOrderList'),
        }
        , {
            path: 'tySpaceSeedListSelect',
            name: 'tySpaceSeedListSelect',
            meta:{
                title:'种子'
            },
            component: () => import(/* webpackChunkName: "shop" */ '../views/ycty/TySpaceSeedListSelect'),
            }
        , {
            path: 'hotelDetail',
            name: 'hotelDetail',
            meta:{
                title:'酒店详细'
            },
            component: () => import(/* webpackChunkName: "hotelDetail" */ '../views/ycty/HotelDetail'),
          }
        ]
    },
    //-厚安护理
    {
        path: '/hahl',
        name: 'hahl',
        component: Hahl,
        children: [{
            path: 'index',
            name: 'hh-index',
            meta:{
                title:'首页'
            },
            component: () => import(/* webpackChunkName: "home" */ '../views/hahl/Index.vue'),
        },
        {
            path: 'history',
            name: 'hh-history',
            meta:{
                title:'品牌故事'
            },
            component: () => import(/* webpackChunkName: "home" */ '../views/hahl/History.vue'),
        },
        {
            path: 'station',
            name: 'hh-station',
            meta:{
                title:'护理站'
            },
            component: () => import(/* webpackChunkName: "home" */ '../views/hahl/Station.vue'),
        },
        {
            path: 'historyDetails',
            name: 'hh-historyDetails',
            meta:{
                title:'品牌故事'
            },
            component: () => import(/* webpackChunkName: "home" */ '../views/hahl/HistoryDetails.vue'),
        },
        {
            path: 'video',
            name: 'hh-video',
            meta:{
                title:'宣传视频'
            },
            component: () => import(/* webpackChunkName: "home" */ '../views/hahl/Video.vue'),
        },
        {
            path: 'mine',
            name: 'hh-mine',
            meta:{
                title:'我的'
            },
            component: () => import(/* webpackChunkName: "home" */ '../views/hahl/Mine'),
        }
        ]
    },
    {
        path: '/shopList',
        name: 'shopList',
        meta:{
            title:'商品列表'
        },
        component: () => import(/* webpackChunkName: "shopList" */ '../views/shop/ShopList.vue'),
    },
    {
        path: '/shopDetails',
        name: 'shopDetails',
        meta:{
            title:'商品详细'
        },
        component: () => import(/* webpackChunkName: "shopList" */ '../views/shop/ShopDetails.vue'),
    },
    {
        path: '/shoppingCart',
        name: 'ShoppingCart',
        meta:{
            title:'购物车'
        },
        component: () => import(/* webpackChunkName: "shopList" */ '../views/shop/ShoppingCart.vue'),
    },
    {
        path: '/shopSettle',
        name: 'ShopSettle',
        meta:{
            title:'商品结算'
        },
        component: () => import(/* webpackChunkName: "shopList" */ '../views/shop/ShopSettle.vue'),
    },
    {
        path: '/addressList',
        name: 'AddressList',
        meta:{
            title:'地址列表'
        },
        component: () => import(/* webpackChunkName: "shopList" */ '../views/shop/AddressList.vue'),
    },
    {
        path: '/addressEdit',
        name: 'AddressEdit',
        meta:{
            title:'地址编辑'
        },
        component: () => import(/* webpackChunkName: "shopList" */ '../views/shop/AddressEdit.vue'),
    },
    {
        path: '/orderList',
        name: 'OrderList',
        meta:{
            title:'订单列表'
        },
        component: () => import(/* webpackChunkName: "shopList" */ '../views/shop/OrderList.vue'),
    },
    {
        path: '/auth',
        name: 'auth',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta:{
            title:'登录'
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/bind-mobile',
        name: 'BindMobile',
        meta:{
            title:'绑定手机'
        },
        component: () => import(/* webpackChunkName: "bindMobile" */ '../views/BindMobile.vue')
    },
    {
        path: '/member',
        name: 'member',
        meta:{
            title:'会员'
        },
        component: () => import(/* webpackChunkName: "my" */ '../views/home/Member.vue'),
    }
]

const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    let openid = store.state.openid;
    //let openid = "o9tqj5imgpgKySvrSQbocmBVmyTo";
    if(process.env.NODE_ENV === 'development'){
        openid = "o9tqj5imgpgKySvrSQbocmBVmyTo";
        store.commit("auth", openid);
    }
    console.log('process.env.NODE_ENV => ',process.env.NODE_ENV);
    if (!openid) {//去授权
        let url = window.location.href;
        if (url.indexOf('code=') != -1) {
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
            });
            let code = url.split('code=')[1].split('&')[0];
            let formData = new FormData();
            formData.append("code", code);
            formData.append("appId", store.state.appId);
            axios.post(window.SITE_CONFIG.baseUrl + "/api/wxmp/public/authLogin", formData).then((res) => {
                console.log('authLogin - res', res);
                Toast.clear();
                store.commit("auth", res.data.openid);
                localStorage.setItem("url", url)
                if (url.indexOf('?code=') != -1) {
                    let goUrl = url.split('?code')[0] + url.split('state=')[1];
                    window.location.href = goUrl
                } else {
                    window.location.href = url;
                }
            }).catch(err => {
                console.log('authLogin - err', err);
            })
        } else {
            Toast.loading({
                duration: 0,
                message: '微信授权中...',
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
            });
            // returnUrl = 'http://24e5472e30.qicp.vip:56806/'+to.name; //测试
            console.log('url - >', url);
            // url = encodeURIComponent(url);
            console.log('encodeURIComponent url - >', url);
            axios.get(window.SITE_CONFIG.baseUrl + "/api/wxmp/public/authUrl", {
                params: {
                    appId: store.state.appId,
                    redirectUrl: url
                }
            }).then((res) => {
                console.log('res', JSON.stringify(res));
                Toast.clear();
                window.location.href = res.data.authUrl;
            }).catch((err) => {
                console.log('err', err);
            })
        }
    }
    if (to.path === "/ycty/videoConduct"
        || to.path === "/ycty/tySpaceDetails"
        || to.path === '/ycty/tySpaceSubmit'
        || to.path === '/ycty/tySpaceSeedListSelect' || to.path === '/ycty/tySpaceOrderList') {
        store.commit("hideTab");
    } else {
        store.commit("showTab");
    }
    next();
})
export default router
