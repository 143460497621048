<template>
    <div class="video-container">
      <div class="close-video" @click="onClose">&times;</div>
        <div class="video-wrapper">
            <video
                class="video-player"
                id="player"
                preload
                autoplay
                loop
                webkit-playsinline="true"
                playsinline="true"
                x-webkit-airplay="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x5-video-ignore-metadata="true"
                controlslist="nodownload"
                poster=""
                :src="videoInfo.url"></video>
        </div>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in videoList" :key="index">
                    <img class="poster-img" :hidden="showIndex===index && myPlayer && myPlayer.readyState" :src="item.poster" />
                    <div class="player-btn" @click="clickPlayer"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swiper from 'swiper';

export default {
    name: "VideoConduct",
  props: {
    show: Boolean
  },
    data() {
        return {
            videoUrl: '',
            videoList: [
                {
                    id: 'a',
                    url: 'http://txmov2.a.yximgs.com/upic/2020/05/04/13/BMjAyMDA1MDQxMzEyNDlfMTU3OTA5NTQzN18yNzk0MzMyNDI0MF8xXzM=_b_B6bad1ef3272cc0450eb97f5c7f02528f.mp4',
                    poster: 'http://cdn.yunhuace.com/Bucket_1f0e3dad99908345f7439f8ffabdffc419/images/2021/01/202101151738346235.png'
                },
                {
                    id: 'b',
                    url: 'http://txmov2.a.yximgs.com/upic/2020/04/08/23/BMjAyMDA0MDgyMzI5NTdfNjUwNzIxNDU5XzI2MzgyNDA2MTYxXzFfMw==_b_B3475bdf64e0082e4b64fcdc3d29aab43.mp4',
                    poster: 'http://cdn.yunhuace.com/Bucket_1f0e3dad99908345f7439f8ffabdffc419/images/2021/01/202101151737364812.png'
                },
                {
                    id: 'c',
                    url: 'https://txmov2.a.yximgs.com/bs2/newWatermark/MTIxMDM3MDYyNTA_zh_4.mp4',
                    poster: 'http://cdn.yunhuace.com/Bucket_1f0e3dad99908345f7439f8ffabdffc419/images/2021/01/202101151737364812.png'
                }
            ],
            showIndex: -1,
            url: '',
            myPlayer: null
        }
    },
    computed: {
        videoInfo() {
            if(this.showIndex < 0 || !this.videoList.length) {
                return {
                    id: 'a',
                    url: '',
                    poster: 'http://cdn.yunhuace.com/Bucket_1f0e3dad99908345f7439f8ffabdffc419/images/2021/01/202101151738346235.png'
                }
            }
            return this.videoList[this.showIndex]
        }
    },
  watch: {
    show(nv) {
      if(nv && this.myPlayer) {
        this.myPlayer.load()
        this.myPlayer.play()
      }
    }
  },
    methods: {
        clickPlayer() {
            if(this.myPlayer) {
                if(this.myPlayer.paused) {
                    this.myPlayer.play()
                } else {
                    this.myPlayer.pause()
                }
            }
        },
        getVideoList(){//获取视频地址列表
          this.$axios.get("/api/wxmp/news/video/list",{
            params:{
              deptId:this.$store.state.deptId
            }
          }).then((res) =>{
            console.log('res',JSON.stringify(res));

            if(res.code === 0) {
              this.videoList = res.data.map(item => {
                return {
                  id: item.videoId,
                  url: item.url,
                  poster: item.coverPic,
                }
              })
            } else {
              this.videoList = []
            }

            this.initSwiper()
          }).catch((err) =>{
            console.log('err',err);
          });
        },
      onClose() {
          if(this.myPlayer) {
            this.myPlayer.pause()
          }
          this.$emit('close')
      },
      initSwiper() {
        let that = this;
        new Swiper('.swiper-container', {
          direction: 'vertical',
          on: {
            init: function (swiper) {
              //Swiper初始化了
              console.log('init', this.activeIndex);
              that.showIndex = this.activeIndex;
              that.myPlayer = document.getElementById('player');
            },
            slideChangeTransitionEnd: function () {
              that.showIndex = this.activeIndex;
              that.myPlayer.play()
            },
          },
        })
      }
    },
    created() {

    },
    mounted() {
        this.getVideoList()
        // this.initSwiper()

    }
}
</script>

<style scoped>
.close-video {
  position: fixed;
  z-index: 99999;
  right: 12px;
  top: 12px;
  background-color: rgba(0,0,0,0.6);
  color: #fff;
  font-size: 28px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.video-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.video-wrapper {
    position: fixed;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.video-player {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.poster-img {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide video {
    width: 100%;
    height: 100%;
}

.swiper-slide {
  height: 100%;
}

video {
    object-fit: fill;
    display: block;
}

.swiper-wrapper {
    position: absolute;
    z-index: 20000;
}

.player-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
</style>
