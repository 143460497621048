import Vue from 'vue'
import Vuex from 'vuex'
import storage from "../utils/storage"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: storage.getItem('token', ''),
        userId: storage.getNumber('userId', 0),
        userType: storage.getNumber('userType', 0),
        userMode: storage.getNumber('userMode', 0), // 1-会员；2-员工
        openid:storage.getItem('openid', ''),//openid
        chainId: 0, //连锁ID
        hotelId: 0, //酒店ID
        shoppingCartGoodsList:JSON.parse(storage.getItem('shoppingCartGoodsList', '[]')),
        showTab:true,
        appId:'wx0194ec8c741c84ce',//渔菜桃源
        deptId:9,//渔菜桃源
        hh_deptId:11,//厚安护理
        defaultBranchId:39,
        hh_defaultBranchId:26,
        location: storage.getObject('location', null),
        hh_location:storage.getObject('hh_location', null),
        showLoading:false,
        windowHeight: window.innerHeight
    },
    getters:{
        getLocation (state) {
            return state.location;
        },
        getHHLocation (state) {
            return state.hh_location;
        },
    },
    mutations: {
        auth(state, data){
            state.openid = data;
            storage.setItem('openid',data);
        },
        hideTab(state){
            state.showTab = false;
        },
        showTab(state){
            state.showTab = true;
        },
        login(state, data) {
            state.token = data.token
            state.userId = data.userId
            state.userType = data.userType
            state.userMode = data.userMode
            storage.setItem('token', data.token)
            storage.setItem('refreshToken', data.refreshToken)
            storage.setItem('expireTime', data.expireTime)
            storage.setItem('userId', data.userId)
            storage.setItem('userType', data.userType)
            storage.setItem('userMode', data.userMode)
        },
        setLocation(state, location){
            state.location = location;
            storage.setItem('location',JSON.stringify(location))
        },
        setHHLocation(state, location){
            state.hh_location = location;
            storage.setItem('hh_location',JSON.stringify(location))
        },
        refreshToken(state, data) {
            state.token = data.token
            storage.setItem('token', data.token)
            storage.setItem('refreshToken', data.refreshToken)
            storage.setItem('expireTime', data.expireTime)
        },
        logout(state) {
            state.token = ''
            state.userId = 0
            state.userType = 0
            state.userMode = 0
            storage.removeItem('token')
            storage.removeItem('refreshToken')
            storage.removeItem('expireTime')
            storage.removeItem('userId')
            storage.removeItem('userType')
            storage.removeItem('userMode')
        },
        addShoppingCartGoods(state, goods){
            let exist =  state.shoppingCartGoodsList.find(item =>{
                return item.goodsId === goods.goodsId && item.itemId === goods.itemId;
            });
            if(exist){
                exist.buyNum = exist.buyNum + goods.buyNum;
            }else{
                state.shoppingCartGoodsList.push(goods);
            }
            storage.setItem('shoppingCartGoodsList',JSON.stringify(state.shoppingCartGoodsList));
        },
        subShoppingCartGoods(state, goods){
            let exist =  state.shoppingCartGoodsList.find(item =>{
                return item.goodsId === goods.goodsId;
            });
            if(exist){
                exist.buyNum = exist.buyNum - goods.buyNum;
                if(exist.buyNum <= 0){
                    let delObj = state.shoppingCartGoodsList.splice(state.shoppingCartGoodsList.findIndex(item => item.goodsId === goods.goodsId && item.itemId === goods.itemId), 1)
                    console.log('delObj',delObj);
                }
            }
            storage.setItem('shoppingCartGoodsList',JSON.stringify(state.shoppingCartGoodsList));
        },
        updateShoppingCartGoods(state, goodsList){
            storage.setItem('shoppingCartGoodsList',JSON.stringify(goodsList));
        }
    },
    actions: {},
    modules: {}
})
