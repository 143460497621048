<template>
  <div class="pages">
    <router-view />
<!--    <div class="ui-tabbar" v-show="showTab">-->
<!--      <van-tabbar v-model="activeName" active-color="#8fc42e" @change="changeTab">-->
<!--        <van-tabbar-item name="tySpace" icon="shop-o">桃源空间</van-tabbar-item>-->
<!--        <van-tabbar-item name="videoConduct" icon="video-o">桃源影视</van-tabbar-item>-->
<!--        <van-tabbar-item name="lifeHome" icon="wap-home-o">生活馆</van-tabbar-item>-->
<!--        <van-tabbar-item name="history" icon="description">桃源故事</van-tabbar-item>-->
<!--        <van-tabbar-item name="joinUs" icon="bag-o">桃源结义</van-tabbar-item>-->
<!--      </van-tabbar>-->
<!--    </div>-->
    <div class="order-list-icon" @click="toOrderList" v-show="showTab">
      <van-badge :content="orderCount">
      <van-icon name="balance-list-o" size="36px" color="#fff" />
      </van-badge>
    </div>
    <van-popup v-model="showVideo" position="right" :style="{ width: '100%' }">
      <video-conduct :show="showVideo" @close="onClose"></video-conduct>
    </van-popup>
    <div class="loading-wrapper" v-if="showLoading">
      <div class="loading">
        <van-loading type="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import VideoConduct from "@/views/ycty/VideoConduct";
export default {
  name: 'Ycty',
  components: {VideoConduct},
  data() {
    return {
      activeName: 'lifeHome',
      lastActiveName: 'lifeHome',
      orderCount: 0,
      showVideo: false,
      showOverlay:true,
      lastTitle:''
    }
  },
  created() {
    this.getOrderCount();
  },
  computed:{
    showTab(){
      return this.$store.state.showTab;
    },
    showLoading(){
      return this.$store.state.showLoading;
    }
  },
  mounted() {
    this.activeName = this.$route.name;
  },
  methods: {
    onClose() {
      this.showVideo=false
      document.title = this.lastTitle;
    },
    changeTab() {
      if(this.activeName === 'videoConduct') {
        this.activeName = this.lastActiveName
        this.lastTitle = document.title;
        document.title = '桃源影视'
        this.showVideo = true
      } else {
        this.$router.replace({
          name: this.activeName
        })
        this.lastActiveName = this.activeName
      }
    },
    toOrderList(){
      this.$router.push("/ycty/tySpaceOrderList")
    },
    getOrderCount(){
      this.$axios.get("/api/wxmp/goods/orderCount",{
        params:{
          openid:this.$store.state.openid
        }
      }).then((res) =>{
        this.orderCount = res.orderCount;
      }).catch((err) =>{

      });
    }
  }
}
</script>
<style lang="scss" scoped>
.order-list-icon{
  position: fixed;
  z-index: 999;
  bottom: 160px;
  right: 30px;
  background-color: $--color-primary;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .7;
  box-shadow: 0 0 20px #999;
}
.loading-wrapper{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: .2;

}
.loading{
  margin-top: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
</style>
